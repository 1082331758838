<div class="row">
    <div class="col-sm-12 col-md-7 order-sm-5">
        <img
            src="/assets/img/me1Smaller.jpg"
            class='img-fluid'
            alt="Jenny Phan"
            title="Jenny Phan"
        >
    </div>
    <div class="col-sm-12 col-md-5 spaceTop mb-3">
        <div class="card">
            <div class="card-block text-center">
                <h4>Happiness is not something</h4>
                <h4>you postpone for the future</h4>
                <h4> it is something you design </h4>
                <h4> for the present </h4>
                <br>Jim Rohn
            </div>
        </div>
    </div>


</div>