<header id="header" class="fixed-top">
  <nav class="navbar navbar-expand-md navbar-light bg-faded ">
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="#">k A s i a n B e a u t i</a>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show">
          <a title="Home" class="nav-link" routerLink="/home" routerLinkActive="router-link-active">Home</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a title="About" class="nav-link" routerLink="/about" routerLinkActive="router-link-active">About</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a title="Dance" class="nav-link" routerLink="/dance" routerLinkActive="router-link-active">Dance</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a title="Health/Beauty" class="nav-link" routerLink="/healthAndBeauty" routerLinkActive="router-link-active">Health/Beauty</a>
        </li>
        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a title="Contact" class="nav-link" routerLink="/contact" routerLinkActive="router-link-active">Contact</a>
        </li>

      </ul>

      <ul class="nav navbar-nav navbar-right">

        <li *ngIf="isUserLoggedIn() == 'N'; else falsyTemplate">
          <a class="nav-link" (click)='login()'>
            <b>Login</b>
          </a>
        </li>
        <li *ngIf="isUserLoggedIn() == 'N'">
          <a class="nav-link" (click)='register()'>
            <b>Register</b>
          </a>
        </li>



        <ng-template #falsyTemplate>
          <li class="dropdown nav-item">
            <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Hi {{currentUser.firstName}}!
              <span class="caret"></span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="nav-link" [routerLink]="['/myaccount/admin']">My Account</a>
              </li>
              <li role="separator" class="divider"></li>
              <li>
                <a class="nav-link" (click)='logout()'>Logout</a>
              </li>
            </ul>
          </li>
        </ng-template>

        <li *ngIf="isUserAdmin() === 'Y'" class="dropdown nav-item">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Admin
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-right">
            <li>
              <a class="nav-link" [routerLink]="['/contactList']">Comments List</a>
            </li>
            <li role="separator" class="divider"></li>
            <li>
              <a class="nav-link" [routerLink]="['/userList']">Registered Users</a>
            </li>
          </ul>
        </li>

      </ul>

      <ul class="navbar-nav flex-row">
        <li class="nav-item">
          <a title="Facebook" class="nav-link px-2" target="_blank" href="https://www.facebook.com/kasianbeauti">
            <span class="fa fa-facebook"></span>
          </a>
        </li>
        <li class="nav-item">
          <a title="Twitter" class="nav-link px-2" target="_blank" href="https://twitter.com/kasianbeauti">
            <span class="fa fa-twitter"></span>
          </a>
        </li>
        <li class="nav-item">
          <a title="youTube" class="nav-link px-2" target="_blank" href="https://www.youtube.com/channel/UCBX9IV86mO8D3xBy0WniUJA">
            <span class="fa fa-youtube"></span>
          </a>
        </li>
        <li class="nav-item">
          <a title="LinkedIn" class="nav-link px-2" target="_blank" href="https://www.linkedin.com/in/jenny-phan-3911031/">
            <span class="fa fa-linkedin"></span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <div [ngClass]="(isHome)?'spacerBeige':'spacer'">
  </div>
</header>