<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">

            <h4 class="modal-title">Login</h4>
            <button type="button" class="close" (click)="close()">&times;</button>
        </div>
        <div class="modal-body">
            <app-modal-alert></app-modal-alert>
            <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !userName.valid }">
                    <p class="legend pull-right">
                        <sup>
                            <span class="fa fa-asterisk font-asterisk"></span>
                        </sup>Required field</p>
                    <label for="userName">Username</label>
                    <sup>
                        <span class="fa fa-asterisk font-asterisk"></span>
                    </sup>
                    <input type="text" class="form-control" name="userName" [(ngModel)]="model.userName" #userName="ngModel" required />
                    <div *ngIf="f.submitted && !userName.valid" class="invalid-feedback">Username is required</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <label for="password">Password</label>
                    <sup>
                        <span class="fa fa-asterisk font-asterisk"></span>
                    </sup>
                    <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                    <div *ngIf="f.submitted && !password.valid" class="invalid-feedback">Password is required</div>
                </div>
                <div class="form-group">
                    <a (click)="register()" class="btn btn-link float-right">Register</a>
                    <button [disabled]="loading" class="btn btn-primary float-right paddingLeft">Login</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />

                </div>
            </form>
        </div>
    </div>
</div>